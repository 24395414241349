import React, { useEffect, useState } from 'react'
import HeaderBar from '../components/HeaderBar'
import Footer from '../components/Footer'
import { useLocation, useParams } from 'react-router-dom';
import { getPageDocument } from '../mgt/collectionsMgt';
import { getDownloadURLFromPath } from '../mgt/imageMgt';
import { ProfileHalfIcon } from '../components/icons';
import GalleryCard from '../components/GalleryCard';
import Gallery from './Gallery';

const Page = () => {
    const { pageId } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page') || false;
    const [loading, setLoading] = useState(true)
    const [pageDoc, setPageDoc] = useState(null)
    const [pageImgUrl, setPageImgUrl] = useState(null)
    const [selectedGallery, setSelectedGallery] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (loading) return

        if (page && pageDoc?.galleries?.length > 0)
            setSelectedGallery(pageDoc.galleries[page - 1])
    }, [page, loading])

    useEffect(() => {
        fetchPage()
    }, [])

    async function fetchPage() {
        const pageDocument = await getPageDocument(pageId)
        setPageDoc(pageDocument)

        if (pageDocument.imageKey) {
            const imgUrl = await getDownloadURLFromPath(pageDocument.imageKey)
            setPageImgUrl(imgUrl)
        }

        setLoading(false)
    }

    if (!pageDoc || loading)
        return <>
            <HeaderBar />
            <div className='pageLoadingContainer'>
                <div className='loadingTxt'>Loading</div>
                <div className='loadingSpinnerBlue' />
            </div>
            <Footer />
        </>

    if (page && selectedGallery !== undefined && selectedGallery !== null)
        return <>
            <Gallery
                gallery={selectedGallery}
                imageFolder={pageDoc.imageFolder}
                publisherName={pageDoc.name}
            />
        </>

    return <>
        <HeaderBar showContact={true} showProfile={true} />
        <div className='profilePic'>
            {
                pageImgUrl ?
                    <div className='profilePicImage'>
                        <img src={pageImgUrl} />
                    </div>
                    :
                    <div className='profilePicImage'>
                        <ProfileHalfIcon size={300} />
                    </div>
            }
        </div>
        <div>{pageDoc.name}</div>
        <div className='pageBio'>{pageDoc.bio}</div>
        <div className='pageDivider' />
        <div className='pageNoOfGalleries'><span style={{ fontWeight: '600' }}>{pageDoc.galleries.length}</span> {pageDoc.galleries.length > 1 ? 'galleries' : 'gallery'} for sale</div>
        <div className='scrollSpacer' />
        {pageDoc.galleries.map((gallery, i) => {
            return <div key={i} className='myGalleriesBody'>
                <GalleryCard
                    imagePath={pageDoc.imageFolder + '/' + gallery.imageKey}
                    name={gallery.name}
                    price={gallery.price}
                    status={gallery.status}
                    id={gallery.id}
                    isPublic={true}
                    pageId={pageId}
                    index={i}
                />
                <div className='scrollSpacer' />
            </div>
        })}
        <div className='scrollSpacer' />
        <div className='scrollSpacer' />
        <Footer />
    </>
}

export default Page