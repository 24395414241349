import { handleSignOut } from "./authMgt"
import { authExpirationHours, generateAstroId, localStorageKeys, localStoragePageKey, pageRefreshRateInMinutes } from "./constants"
import { auth } from "./firebase"

function setLocalStorageUser(user) {
    try {
        if (!user) {
            localStorage.removeItem(localStorageKeys.userDoc)
            return
        }

        user.lastUpdated = new Date().getTime()
        localStorage.setItem(localStorageKeys.userDoc, JSON.stringify(user))
    } catch (error) {
        console.log('Error setting local storage user:', error)
    }
}

async function getLocalStorageUser() {
    try {
        var authResult = { result: true, text: 'OK' }
        var localStorageResult = { result: true, text: 'OK' }

        if (!auth.currentUser?.uid) {
            authResult.result = false
            authResult.text = 'User is not logged in.'
        }

        const now = new Date().getTime()
        const timeDifferenceInHours = (time) => {
            return (now - time) / 3600000
        }

        if (authResult.result === true) {
            const lastLoginAt = auth.currentUser.metadata.lastLoginAt
            if (timeDifferenceInHours(lastLoginAt) > authExpirationHours) {
                authResult.result = false
                authResult.text = 'User session expired'
            }
        }

        if (authResult.result === false) {
            console.log(`Auth [${authResult.text}] - Signing out.`)
            await handleSignOut()
            setLocalStorageUser(false)
            return false
        }

        var user = localStorage.getItem(localStorageKeys.userDoc)
        user = JSON.parse(user)
        if (!user) {
            return false
        } else {
            if (user.id !== auth.currentUser.uid) {
                localStorageResult.result = false
                localStorageResult.text = 'Local storage user does not match auth user.'
            }
        }

        if (localStorageResult.result === false) {
            console.log(`Local storage [${localStorageResult.text}] - Signing out.`)
            await handleSignOut()
            setLocalStorageUser(false)
            return false
        }

        return user
    } catch (error) {
        console.log('Error getting local storage user:', error)
        return false
    }
}

function getLocalStorageBuyerTransactions() {
    try {
        const transactions = localStorage.getItem(localStorageKeys.buyerTransactions)
        if (!transactions) {
            return false
        } else {
            return JSON.parse(transactions)
        }
    } catch (error) {
        console.log('Error getting local storage buyer transactions:', error)
        return []
    }
}

function setLocalStorageBuyerTransactions(transactions) {
    try {
        if (!transactions) {
            localStorage.removeItem(localStorageKeys.buyerTransactions)
            return
        }

        localStorage.setItem(localStorageKeys.buyerTransactions, JSON.stringify(transactions))
    } catch (error) {
        console.log('Error setting local storage buyer transactions:', error)
    }
}

function setLocalStoragePage(page) {
    try {
        const key = localStoragePageKey(page.id)

        if (!page) {
            localStorage.removeItem(key)
            return
        }

        page.lastUpdated = new Date().getTime()
        localStorage.setItem(key, JSON.stringify(page))
    } catch (error) {
        console.log('Error setting local storage page:', error)
    }
}

function getLocalStoragePage(pageId) {
    try {
        const key = localStoragePageKey(pageId)
        const page = localStorage.getItem(key)
        const pageDoc = JSON.parse(page)

        if (!pageDoc) {
            return false
        }

        const now = new Date().getTime()
        const timeDifferenceInMinutes = (time) => {
            return (now - time) / 60000
        }

        if (timeDifferenceInMinutes(pageDoc.lastUpdated) > pageRefreshRateInMinutes) {
            return false
        }

        return pageDoc
    } catch (error) {
        console.log('Error getting local storage page:', error)
        return false
    }
}

function getAccessKey() {
    const key = localStorage.getItem(localStorageKeys.accessKey)
    if (!key) {
        const newKey = generateAstroId()
        setAccessKey(newKey)
        return newKey
    } else {
        return key
    }
}

function setAccessKey(key) {
    localStorage.setItem(localStorageKeys.accessKey, key)
}

export {
    getAccessKey,
    getLocalStorageUser,
    setLocalStorageUser,
    setLocalStorageBuyerTransactions,
    getLocalStorageBuyerTransactions,
    getLocalStoragePage,
    setLocalStoragePage
}