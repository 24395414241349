import HeaderBar from '../components/HeaderBar'
import Footer from '../components/Footer'
import InteractiveBtn from '../components/InteractiveBtn'
import { auth } from '../mgt/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import { handleSignInWithPopupGoogle } from '../mgt/authMgt'
import { useEffect, useState } from 'react'
import { ApplePayIcon, GoogleIcon, GooglePayIcon, MaestroIcon, PaypalIcon, VisaIcon } from '../components/icons'
import { getUserDocument } from '../mgt/collectionsMgt'

const Home = () => {
    const [userDoc, setUserDoc] = useState(null)
    const [user, loading] = useAuthState(auth)
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (user && !loading) {
            const fetchUserDoc = async () => {
                const userDocument = await getUserDocument(user.uid)
                setUserDoc(userDocument)
            }
            fetchUserDoc()
        }
    }, [user, loading])

    function handleLogin() {
        navigate('/login')
    }

    function handleRegister() {
        navigate('/register')
    }

    async function tryLoginWithGoogle() {
        const userDoc = await handleSignInWithPopupGoogle()
        if (userDoc) {
            navigate('/profile')
        }
    }

    if (loading)
        return <>
            <HeaderBar />
            <div className='pageLoadingContainer'>
                <div className='loadingTxt'>Loading</div>
                <div className='loadingSpinnerBlue' />
            </div>
            <Footer />
        </>

    return (
        <>
            <HeaderBar showContact={true} showProfile={user ? true : false} />
            {userDoc?.type == 'seller' ?
                <>
                    <p className='heroText'>Let's begin!</p>
                    <p className='heroSubText'>Create a new gallery below</p>
                    <p className='heroSubText' style={{ fontSize: '15pt', fontWeight: '200', marginBottom: '1rem' }}>Publish your gallery to start selling</p>
                    <InteractiveBtn
                        className='loginRegisterBtn'
                        text='Create'
                        onClick={() => navigate('/gallery/create')}
                    />
                </> : <>
                    <p className='heroText'>Sell safely, buy confidently</p>
                    <p className='heroSubText' style={{ margin: '1rem 3rem 0 3rem' }}>Make money with content, the easy way.</p>
                    <p className='heroSubText' style={{ fontSize: '15pt', fontWeight: '200', marginBottom: '0rem', width: '100%' }}>All payments are secured.</p>
                    <div style={{ gap: '10px', display: 'flex' }}>
                        <MaestroIcon size={40} />
                        <PaypalIcon size={40} />
                        <VisaIcon size={40} />
                        <ApplePayIcon size={40} />
                        <GooglePayIcon size={40} />
                    </div>
                    {!user && <div>
                        <div style={{ gap: '10px', display: 'flex', flexDirection: 'row' }}>
                            <InteractiveBtn
                                className='loginRegisterBtn loginRegisterBtnInverted'
                                text='Sign up'
                                onClick={handleRegister}
                            />
                            <InteractiveBtn
                                className='loginRegisterBtn'
                                text='Log in'
                                onClick={handleLogin}
                            />
                        </div>
                        <InteractiveBtn
                            className='loginWithGoogleBtn loginGoogleHome'
                            onClick={tryLoginWithGoogle}
                            text='Continue with Google'
                            icon={<GoogleIcon size={20} />}
                        />
                    </div>}
                </>
            }
            <Footer />
        </>
    )
}

export default Home