import React, { useEffect, useRef, useState } from 'react'
import { getDownloadURLFromPath } from '../mgt/imageMgt'
import HeaderBar from '../components/HeaderBar'
import Footer from '../components/Footer'
import InteractiveBtn from '../components/InteractiveBtn'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, testStripeCheckoutCreate } from '../mgt/firebase'
import { useNavigate } from 'react-router-dom'
import { generateAstroId, localStorageKeys } from '../mgt/constants'
import { AmexIcon, ApplePayIcon, BancontactIcon, GooglePayIcon, MaestroIcon, PaypalIcon, StripeIcon, StripeTxtIcon, VisaIcon } from '../components/icons'

const Gallery = (props) => {
    const navigate = useNavigate()
    const [user, loading] = useAuthState(auth)
    const [imageUrl, setImageUrl] = useState('')
    const [imageUrls, setImageUrls] = useState([])
    const [scrollAllign, setScrollAllign] = useState('justifyCenter')
    const [checkoutLoading, setCheckoutLoading] = useState(false)
    const [blockSpam, setBlockSpam] = useState(false)
    const { gallery, imageFolder, publisherName } = props
    const galleryRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (gallery.previewKeys.length > 1)
            setScrollAllign('')
        else
            setScrollAllign('justifyCenter')

        if (gallery)
            getDownloadURLs()
    }, [gallery])

    async function getDownloadURLs() {
        const displayImgPath = imageFolder + '/' + gallery?.imageKey
        const url = await getDownloadURLFromPath(displayImgPath)
        setImageUrl(url)

        const urls = await Promise.all(gallery?.previewKeys?.map(async (key) => {
            const previewImgPath = imageFolder + '/' + key
            return await getDownloadURLFromPath(previewImgPath)
        }))
        setImageUrls(urls)
    }

    async function handleBuyGallery() {
        if (loading || checkoutLoading || blockSpam) return

        setCheckoutLoading(true)
        setBlockSpam(true)

        const redirectURl = window.location.pathname + window.location.search
        if (!user) {
            setCheckoutLoading(false)
            setBlockSpam(false)
            console.log('Navigating to ')
            navigate('/login?redirect=' + redirectURl)
            return
        }
        await createStripeCheckout()
    }

    function createPendingTransaction() {
        const transactionId = generateAstroId()

        const pendingTransaction = {
            id: transactionId,
            status: 'pending',
            dateCreated: new Date().getTime(),
            publisherName: publisherName,
            galleryDisplayImgPath: imageFolder + '/' + gallery.imageKey,
            galleryName: gallery.name,
        }

        const pendingTransactions = JSON.parse(localStorage.getItem(localStorageKeys.buyerTransactions)) || []
        pendingTransactions.push(pendingTransaction)
        localStorage.setItem(localStorageKeys.buyerTransactions, JSON.stringify(pendingTransactions))

        return transactionId
    }

    async function createStripeCheckout() {
        try {
            const transactionId = createPendingTransaction()

            const req = {
                transactionId: transactionId,
                galleryId: gallery.id,
                publisherId: gallery.publisherId,
                galleryDisplayImg: imageUrl,
                originalUrl: window.location.href,
                baseUrl: window.location.origin
            }
            console.log('Creating stripe checkout with:', req)
            const response = await testStripeCheckoutCreate(req)
            const result = response.data

            if (result.error) {
                console.log('Error occured:', result.message)
                setCheckoutLoading(false)
                setTimeout(() => {
                    setBlockSpam(false)
                }, 2000)
                return
            }

            if (result.success) {
                console.log('Success:', result.message)
                setCheckoutLoading(false)
                setTimeout(() => {
                    setBlockSpam(false)
                }, 2000)
                window.location.href = result.checkoutUrl
            }

        } catch (error) {
            console.error(error)
            setCheckoutLoading(false)
            setTimeout(() => {
                setBlockSpam(false)
            }, 2000)
        }
    }

    useEffect(() => {
        if (imageUrls.length === 1)
            return

        const galleryContainer = galleryRef.current;
        if (galleryContainer) {
            // Simulate scrolling right
            galleryContainer.scrollBy({
                left: 20, // Adjust the value as needed
                behavior: 'smooth',
            });

            // Delay to allow scrolling to complete
            setTimeout(() => {
                // Simulate scrolling left
                galleryContainer.scrollBy({
                    left: -10, // Adjust the value as needed
                    behavior: 'smooth'
                });
            }, 100); // Adjust the delay time as needed
        }
    }, [imageUrls]);

    return (
        <>
            <HeaderBar showContact={true} showProfile={true} />
            <div className='scrollSpacer' />
            <div className='myGalleryCard'>
                <div className='myGalleryCardImage'>
                    <img src={imageUrl} />
                </div>
                <div className='myGalleryName'>{gallery?.name}</div>
                <div className='scrollSpacerSmall' />
                <div className={`galleryImagesContainer ${scrollAllign}`} ref={galleryRef}>
                    <div className='verticalSpacer' />
                    {imageUrls?.map((url, i) => (
                        <div key={i} className='galleryImage'>
                            <img src={url} />
                        </div>
                    ))}
                    <div className='verticalSpacer' />
                </div >
                <div className='galleryDivider' />
                <div className='scrollSpacerSmall' />
                <div className='myGalleryImgCount'>This gallery contains <span style={{ fontWeight: '300' }}>{gallery.previewKeys.length}</span>{` image${gallery.previewKeys.length > 1 ? 's' : ''}`}</div>
            </div>
            <div className='scrollSpacer' />
            <div className='purchaseInfoTos'>
                {'By purchasing this gallery, you agree to the '}
                <span
                    className='purchaseInfoTos purchaseInfoTosClick'
                    onClick={() => navigate('/terms-of-service')}
                >Terms of Service</span>
                {' and '}
                <span
                    className='purchaseInfoTos purchaseInfoTosClick'
                    onClick={() => navigate('/privacy-policy')}
                >Privacy Policy</span>.
            </div>
            <div className='scrollSpacer' />
            <InteractiveBtn
                text={`Reveal for €${String(gallery.price).replace(',', ' ').replace('.', ',')}`}
                className={`loginRegisterBtn green ${!checkoutLoading && 'checkoutShadow'}`}
                onClick={handleBuyGallery}
                showLoadingOverlay={checkoutLoading}
            />
            <div className='poweredByStripe'>Powered by
                <div className='stripeTxtIcon'>
                    <StripeTxtIcon size={40} />
                </div>
            </div>
            <div style={{ gap: '10px', display: 'flex' }}>
                <MaestroIcon size={40} />
                <PaypalIcon size={40} />
                <VisaIcon size={40} />
                <ApplePayIcon size={40} />
                <GooglePayIcon size={40} />
            </div>
            <div className='galleryDivider' />
            {!user ?
                <div className='purchaseInfo'>
                    You'll need to create an account to purchase this gallery. The content will be available for download in your account after the payment has been verified.
                </div>
                :
                <div className='purchaseInfo'>
                    The content will be available for download in your account once the payment has been verified.
                </div>
            }
            <InteractiveBtn
                text='View other galleries'
                className='loginRegisterBtn'
                // Onclick: navigate to current url without &page=...
                onClick={() => navigate(window.location.pathname.split('&page=')[0])}
            />
            <div className='scrollSpacer' />
            <div className='scrollSpacer' />
            <Footer />
        </>
    )
}

export default Gallery