import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage"
import { storage } from "./firebase"

async function uploadImgToStorage(file, path) {
    if (!file)
        return ''

    try {
        console.log('[FIREBASE] Uploading image to storage:', path)
        const storageRef = ref(storage, path)
        const snapshot = await uploadBytes(storageRef, file)
        await getDownloadURL(snapshot.ref)
        return path
    } catch (error) {
        const errorCode = error.code
        const errorMessage = error.message
        console.log('Image upload error:', errorCode, errorMessage)
        return ''
    }
}

async function deleteImgFromStorage(path) {
    try {
        const storageRef = ref(storage, path)
        if (!storageRef.name)
            return

        await deleteObject(storageRef)
    } catch (error) {
        const errorCode = error.code
        const errorMessage = error.message
        console.log('Image delete error:', errorCode, errorMessage)
    }
}

async function getDownloadURLFromPath(path, useFirebaseFunction) {
    try {
        if (useFirebaseFunction) {
            const storageRef = ref(storage, path)
            const downloadUrl = await getDownloadURL(storageRef)
            return downloadUrl
        } else {
            const downloadUrl = constructImgUrlFromPath(path)
            return downloadUrl
        }
    } catch (error) {
        const errorCode = error.code
        const errorMessage = error.message
        console.log('Download URL error:', errorCode, errorMessage)
        return ''
    }
}

function constructImgUrlFromPath(path) {
    const baseUrl = 'https://firebasestorage.googleapis.com/v0/b/reveell-me.appspot.com/o/'
    const suffix = '?alt=media'
    const pathArray = path.split('/')
    return `${baseUrl}${pathArray.join('%2F')}${suffix}`
}

async function cropImage(file, width, height) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = async (e) => {
            const img = new Image()
            img.src = e.target.result
            img.onload = async () => {
                // 3. Crop image to 500x500 centered
                const canvas = document.createElement('canvas');
                const aspectRatio = img.width / img.height;
                if (aspectRatio >= 1) {
                    // Horizontal image
                    height = width / aspectRatio;
                } else {
                    // Vertical image
                    width = height * aspectRatio;
                }

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
                canvas.toBlob(async (blob) => {
                    resolve(blob)
                }, 'image/jpeg', 0.9)
            }
        }
    })
}

async function cropDisplayImage(file, targetWidth, targetHeight) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = async () => {
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;
                let x = 0;
                let y = 0;

                const aspectRatio = img.width / img.height;

                if (aspectRatio > targetWidth / targetHeight) {
                    // Image is wider
                    width = img.height * (targetWidth / targetHeight);
                    x = (img.width - width) / 2;
                } else {
                    // Image is taller
                    height = img.width * (targetHeight / targetWidth);
                    y = (img.height - height) / 2;
                }

                canvas.width = targetWidth;
                canvas.height = targetHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, x, y, width, height, 0, 0, targetWidth, targetHeight);
                canvas.toBlob(async (blob) => {
                    resolve(blob);
                }, 'image/jpeg', 0.9);
            };
        };
    });
}

async function blurImage(imageBlob) {
    const croppedImgBlob = await cropDisplayImage(imageBlob, 500, 500)

    return new Promise((resolve) => {
        const img = new Image()
        img.src = URL.createObjectURL(croppedImgBlob)
        img.onload = async () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            canvas.width = img.width
            canvas.height = img.height
            ctx.filter = `blur(50px)`
            ctx.drawImage(img, 0, 0, img.width, img.height)
            canvas.toBlob((blob) => {
                resolve(blob)
            }, 'image/jpeg', 0.9)
        }
    })
}

async function imageUrlToBlob(imageUrl) {
    return new Promise((resolve) => {
        const img = new Image()
        img.crossOrigin = 'Anonymous'
        img.src = imageUrl
        img.onload = async () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            canvas.width = img.width
            canvas.height = img.height
            ctx.drawImage(img, 0, 0, img.width, img.height)
            canvas.toBlob((blob) => {
                resolve(blob)
            }, 'image/jpeg', 0.9)
        }
    })
}

export {
    uploadImgToStorage,
    cropImage,
    cropDisplayImage,
    blurImage,
    getDownloadURLFromPath,
    deleteImgFromStorage,
    imageUrlToBlob
}
