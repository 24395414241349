import React, { useEffect, useState } from 'react'
import { handleSignInWithEmail, handleSignInWithPopupGoogle } from '../mgt/authMgt'
import { useLocation, useNavigate } from 'react-router-dom'
import InteractiveBtn from '../components/InteractiveBtn'
import { GoogleIcon, RevealIcon } from '../components/icons'
import HeaderBar from '../components/HeaderBar'
import Footer from '../components/Footer'
import { auth } from '../mgt/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

const Login = () => {
    const [user, loading] = useAuthState(auth)
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirect = queryParams.get('redirect') || false;
    const [emailInput, setEmailInput] = useState('')
    const [passwordInput, setPasswordInput] = useState('')
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [emailError, setEmailError] = useState('')
    const [emailErrText, setEmailErrText] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [passwordErrText, setPasswordErrText] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (loading) return
        if (redirect && user) {
            const newRedirect = redirect.split('?')[0]
            navigate(newRedirect)
        }
    }, [loading])

    const tryLoginWithGoogle = async () => {
        const userDoc = await handleSignInWithPopupGoogle()
        if (userDoc) {
            handleLoginSuccess()
        }
    }

    const tryLoginWithEmail = async () => {
        if (!checkEmailInput(true)) return
        if (!checkPasswordInput(true)) return

        const result = await handleSignInWithEmail(emailInput, passwordInput)

        if (result.success) {
            if (result.userDoc) {
                handleLoginSuccess()
            }
        } else {
            setPasswordError('show')
            setPasswordErrText(result.errorMessage)
            return
        }
    }

    function checkEmailInput(checkEmpty) {
        if (emailInput === '') {
            if (checkEmpty === true) {
                setEmailError('show')
                setEmailErrText('Please enter your email')
                return false
            } else {
                setEmailError('')
                return true
            }
        }

        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (emailRegex.test(emailInput)) {
            setEmailError('')
            return true
        } else {
            setEmailError('show')
            setEmailErrText('Please enter a valid email address')
            return false
        }
    }

    function checkPasswordInput(checkEmpty) {
        if (passwordInput === '') {
            if (checkEmpty === true) {
                setPasswordError('show')
                setPasswordErrText('Please enter your password')
                return false
            } else {
                setPasswordError('')
                return true
            }
        }
        return true
    }

    function handleLoginSuccess() {
        if (redirect) {
            navigate(redirect)
        } else {
            navigate('/profile')
        }
    }

    function switchToRegister() {
        if (redirect)
            navigate(`/register?redirect=${redirect}`)
        else
            navigate('/register')
    }

    return (
        <>
            <HeaderBar showContact={true} />
            <div className='registerTitle'>Log in to your account</div>
            <div className='registerSubtitleWrapper'>
                <div className='registerSubtitle'>Don't have an account yet?
                    <span className='registerSubtitleLink' onClick={switchToRegister}> Sign up </span>
                </div>
            </div>
            <div className='registerInputLbl'>Email</div>
            <input
                placeholder='Email'
                className='registerInput'
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                onBlur={checkEmailInput}
            />
            <div className={`inputErrorLbl ${emailError}`}>{emailErrText}</div>
            <div className='registerInputLbl'>Password</div>
            <div className='registerInputWrapper'>
                <input
                    placeholder='Password'
                    className='registerInput'
                    value={passwordInput}
                    onChange={(e) => setPasswordInput(e.target.value)}
                    onBlur={checkPasswordInput}
                    type={passwordVisible ? 'text' : 'password'}
                />
                <div className='showPasswordIcon' onClick={() => setPasswordVisible(!passwordVisible)}>
                    <RevealIcon size={30} transparent={true} grey={!passwordVisible} />
                </div>
            </div>
            <div className={`inputErrorLbl ${passwordError}`}>{passwordErrText}</div>
            <div>
                <InteractiveBtn className='loginRegisterBtn' onClick={tryLoginWithEmail} text='Continue with email' stretch={true} />
                <InteractiveBtn className='loginWithGoogleBtn' onClick={tryLoginWithGoogle} text='Continue with Google' icon={<GoogleIcon size={20} />} />
            </div>
            <Footer />
        </>
    )
}

export default Login
