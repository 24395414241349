import React, { useEffect, useState } from 'react'
import HeaderBar from '../components/HeaderBar'
import Footer from '../components/Footer'
import { getDownloadURLFromPath, imageUrlToBlob } from '../mgt/imageMgt'
import InteractiveBtn from '../components/InteractiveBtn'
import { getBuyerTransactionDocuments, getTransactionDoc, getUserDocument, modifyUserDocument } from '../mgt/collectionsMgt'
import { setLocalStorageBuyerTransactions } from '../mgt/cachingMgt'
import JSZip from 'jszip';
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../mgt/firebase'

const Orders = () => {
    const [user, loading] = useAuthState(auth)
    const [displayImageUrls, setDisplayImageUrls] = useState([])
    const [orders, setOrders] = useState([])

    useEffect(() => {
        if (!loading && user)
            getBuyerTransactions()
    }, [user, loading])

    async function getBuyerTransactions() {
        let transactions = await getBuyerTransactionDocuments(user.uid)
        checkPendingOrders(transactions)
    }

    async function checkPendingOrders(transactions) {
        // Get pending orders from Firestore
        const newOrders = await Promise.all(transactions.map(async order => {
            if (order?.status === 'pending') {
                const fulfilledOrder = await getTransactionDoc(order.id)
                return fulfilledOrder ? fulfilledOrder : order
            } else
                return order
        }))

        // Sort by pending first, then by timestamp
        newOrders.sort((a, b) => {
            if (a?.status === 'pending' && b?.status !== 'pending') return -1
            if (a?.status !== 'pending' && b?.status === 'pending') return 1
            return b.dateCreated.seconds - a.dateCreated.seconds
        })

        // Check if downloaded and change status to downloaded
        const userDoc = await getUserDocument(user.uid)
        const downloadedOrders = userDoc?.downloads ? userDoc.downloads : []
        newOrders.forEach(order => {
            if (downloadedOrders.includes(order.id))
                order.status = 'downloaded'
        })
        setOrders(newOrders)
        setLocalStorageBuyerTransactions(newOrders)
    }

    useEffect(() => {
        getDisplayImageUrls()
    }, [orders])

    async function getDisplayImageUrls() {
        const urls = []
        for (let i = 0; i < orders.length; i++) {
            const order = orders[i]
            const imageUrl = await getDownloadURLFromPath(order.galleryDisplayImgPath)
            urls.push(imageUrl)
        }
        setDisplayImageUrls(urls)
    }

    async function handleDownloadGallery(order) {
        if (order?.status === 'pending' || order?.status === 'downloaded') return

        // Download gallery images and create zip
        const zip = new JSZip()
        const folder = zip.folder(order.galleryName)
        await Promise.all(order.galleryImgPaths.map(async (path, index) => {
            const url = await getDownloadURLFromPath(path)
            const blob = await imageUrlToBlob(url)
            return folder.file(`image${index + 1}.jpg`, blob)
        }))

        // Download zip
        zip.generateAsync({ type: 'blob' }).then((content) => {
            const url = window.URL.createObjectURL(content)
            const a = document.createElement('a')
            a.href = url
            a.download = order.galleryName + '.zip'
            a.click()
        })

        // Save transaction to downloads in user document
        const userDoc = await getUserDocument(user.uid)
        const newDownloads = userDoc?.downloads ? [...userDoc.downloads, order.id] : [order.id]
        userDoc.downloads = newDownloads
        await modifyUserDocument(userDoc)

        // Refresh orders
        checkPendingOrders(orders)
    }

    return (
        <>
            <HeaderBar showProfile={true} showContact={true} />
            <div className='scrollSpacer' />
            <div>Order history</div>
            <div className='scrollSpacer' />
            <div className='orderHistoryContainer'>
                {orders?.map((order, index) => {

                    let timestamp
                    if (order?.status === 'pending')
                        timestamp = new Date(order.dateCreated).toLocaleString()
                    else
                        timestamp = new Date(order.dateCreated.seconds * 1000).toLocaleString()
                    const buttonText = order?.status === 'pending' ? 'Pending'
                        : order?.status === 'downloaded' ? 'Downloaded'
                            : 'Download'

                    return (
                        <div key={index} className='orderHistoryItem' style={{ opacity: order?.status === 'pending' ? 0.55 : 1 }}>
                            <div className='orderHistoryItemRow1'>
                                <img
                                    alt='image'
                                    src={displayImageUrls[index]}
                                    className='orderHistoryImage'
                                />
                                <div>
                                    <div className='orderHistoryItemTitle'>{order.galleryName}</div>
                                    <div className='orderHistoryItemPublisher'>A gallery by {order.publisherName}</div>
                                    {order?.status !== 'pending' && <div className='orderHistoryItemPrice'>€{String(order.amount).replace(',', ' ').replace('.', ',')}</div>}
                                </div>
                            </div>
                            <div className='orderHistoryItemRow2'>
                                <div className='orderHistoryItemTime'>{timestamp}</div>
                                <InteractiveBtn
                                    className={`orderHistoryItemBtn ${order?.status === 'pending' && 'orderHistoryItemBtnPending'} ${order?.status === 'downloaded' && 'orderHistoryItemBtnDownloaded'}`}
                                    text={buttonText}
                                    onClick={() => handleDownloadGallery(order)}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
            <Footer />
        </>
    )
}

export default Orders