import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getDownloadURLFromPath } from '../mgt/imageMgt'

const GalleryCard = (props) => {
    const { imagePath, name, price, status, id, isPublic, pageId, index } = props
    const navigate = useNavigate()
    const [imageUrl, setImageUrl] = useState('')

    useEffect(() => {
        getDownloadURL()
    }, [])

    async function getDownloadURL() {
        const url = await getDownloadURLFromPath(imagePath)
        setImageUrl(url)
    }

    function editGallery() {
        navigate(`/gallery/edit/${id}`)
    }

    function openGallery() {
        navigate(`/u/${pageId}?page=${index + 1}`)
    }

    return (
        <div className='myGalleryCard' onClick={!isPublic ? editGallery : openGallery}>
            <div className='myGalleryCardImage'>
                <img src={imageUrl} />
            </div>
            <div className='myGalleryCardName'>{name}</div>
            {!isPublic && (
                status === 'Published' ?
                    <div className='myGalleryCardStatusPublished'>Published</div>
                    :
                    <div className='myGalleryCardStatusPrivate'>Private</div>
            )}
            <div className='myGalleryCardPrice'>{isPublic && 'Reveal for '}€{String(price).replace(',', ' ').replace('.', ',')}</div>
        </div>
    )
}

export default GalleryCard