import React, { useEffect, useState } from 'react'
import { handleRegisterWithEmail, handleSignInWithPopupGoogle } from '../mgt/authMgt'
import { getLocalStorageUser } from '../mgt/cachingMgt'
import { useLocation, useNavigate } from 'react-router-dom'
import InteractiveBtn from '../components/InteractiveBtn'
import { GoogleIcon, RevealIcon } from '../components/icons'
import HeaderBar from '../components/HeaderBar'
import Footer from '../components/Footer'
import { auth } from '../mgt/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

const Register = () => {
    const [user, loading] = useAuthState(auth)
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirect = queryParams.get('redirect') || false;
    const [displayNameInput, setDisplayNameInput] = useState('')
    const [emailInput, setEmailInput] = useState('')
    const [passwordInput, setPasswordInput] = useState('')
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [emailEditable, setEmailEditable] = useState(false)
    const [localUser, setLocalUser] = useState(null)
    const [nameError, setNameError] = useState('')
    const [nameErrText, setNameErrText] = useState('')
    const [emailError, setEmailError] = useState('')
    const [emailErrText, setEmailErrText] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [passwordErrText, setPasswordErrText] = useState('')

    async function getRegisteredUser() {
        const user = await getLocalStorageUser()
        setLocalUser(user)

        const isEmailEditable = !user || user?.email === '' ? true : false
        setEmailEditable(isEmailEditable)
    }

    useEffect(() => {
        getRegisteredUser()
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (loading) return
        if (redirect && user) {
            const newRedirect = redirect.split('?')[0]
            navigate(newRedirect)
        }
    }, [loading])

    const tryLoginWithGoogle = async () => {
        const userDoc = await handleSignInWithPopupGoogle()
        if (userDoc) {
            handleRegisterSuccess()
        }
    }

    const tryRegisterWithEmail = async () => {
        // if (!allFieldsOk) return
        if (!checkNameInput()) return
        if (!checkEmailInput(true)) return
        if (!checkPasswordInput(true)) return

        const userDoc = await handleRegisterWithEmail(emailInput, passwordInput, displayNameInput)
        if (userDoc) {
            handleRegisterSuccess()
        }
    }

    function checkNameInput() {
        if (displayNameInput === '') {
            setNameError('show')
            setNameErrText('Your name cannot be empty')
            return false
        } else {
            setNameError('')
            return true
        }
    }
    useEffect(() => {
        if (displayNameInput !== '') {
            setNameError('')
            setNameErrText('')
        }
    }, [displayNameInput])

    function checkEmailInput(checkEmpty) {
        if (emailInput === '') {
            if (checkEmpty === true) {
                setEmailError('show')
                setEmailErrText('Please enter your email')
                return false
            } else {
                setEmailError('')
                return true
            }
        } else {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            if (emailRegex.test(emailInput)) {
                setEmailError('')
                return true
            } else {
                setEmailError('show')
                setEmailErrText('Please enter a valid email address')
                return false
            }
        }
    }
    useEffect(() => {
        if (emailInput !== '') {
            setEmailError('')
            setEmailErrText('')
        }
    }, [emailInput])

    useEffect(() => {
        if (passwordInput.length >= 6) {
            setPasswordError('')
            setPasswordErrText('')
        }
    }, [passwordInput])

    function checkPasswordInput(checkEmpty) {
        if (passwordInput === '') {
            if (checkEmpty === true) {
                setPasswordError('show')
                setPasswordErrText('Please enter your password')
                return false
            } else {
                setPasswordError('')
                return true
            }
        }

        if (passwordInput.length < 6) {
            setPasswordError('show')
            setPasswordErrText('Your password must be at least 6 characters long')
            return false
        }

        return true
    }

    function handleRegisterSuccess() {
        if (redirect)
            navigate(redirect)
        else
            navigate('/profile')
    }

    function switchToLogin() {
        if (redirect)
            navigate(`/login?redirect=${redirect}`)
        else
            navigate('/login')
    }

    return (
        <>
            <HeaderBar showContact={true} />
            <div className='registerTitle'>Set up a new account</div>
            <div className='registerSubtitleWrapper'>
                <div className='registerSubtitle'>Already have an account?
                    <span className='registerSubtitleLink' onClick={switchToLogin}> Log in </span>
                </div>
            </div>
            <div className='registerInputLbl'>Display Name</div>
            <input
                className='registerInput'
                placeholder='Display Name'
                value={displayNameInput}
                onChange={(e) => setDisplayNameInput(e.target.value)}
            />
            <div className={`inputErrorLbl ${nameError}`}>{nameErrText}</div>
            <div className='registerInputLbl'>Email</div>
            <input
                placeholder='Email'
                value={emailEditable ? emailInput : localUser?.email}
                onChange={(e) => setEmailInput(e.target.value)}
                onBlur={checkEmailInput}
                className={emailEditable ? 'registerInput' : 'registerInput registerInputNotEditable'}
                readOnly={!emailEditable ? true : false}
            />
            <div className={`inputErrorLbl ${emailError}`}>{emailErrText}</div>
            {emailEditable &&
                <>
                    <div className='registerInputLbl'>Password</div>
                    <div className='registerInputWrapper'>
                        <input
                            type={passwordVisible ? 'text' : 'password'}
                            placeholder='Password'
                            className='registerInput'
                            onChange={(e) => setPasswordInput(e.target.value)}
                            value={passwordInput}
                        onBlur={checkPasswordInput}
                        />
                        <div className='showPasswordIcon' onClick={() => setPasswordVisible(!passwordVisible)}>
                            <RevealIcon size={30} transparent={true} grey={!passwordVisible} />
                        </div>
                    </div>
                <div className={`inputErrorLbl ${passwordError}`}>{passwordErrText}</div>
                </>
            }
            <div>
                <InteractiveBtn className='loginRegisterBtn' onClick={tryRegisterWithEmail} text='Create a new account' stretch={true} />
                <InteractiveBtn className='loginWithGoogleBtn' onClick={tryLoginWithGoogle} text='Continue with Google' icon={<GoogleIcon size={20} />} />
            </div>
            <Footer />
        </>
    )
}

export default Register
