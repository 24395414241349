import React, { useState } from 'react';

const InteractiveBtn = (props) => {
    const [isPressed, setIsPressed] = useState(false);
    const setPressedTrue = () => { setIsPressed(true); };
    const setPressedFalse = () => { setIsPressed(false); };

    return (
        <button
            style={{
                filter: isPressed ? 'brightness(0.9)' : 'brightness(1)',
                width: props.stretch ? '100%' : 'auto',
            }}
            onTouchStart={setPressedTrue}
            onTouchEnd={setPressedFalse}
            onTouchCancel={setPressedFalse}
            onMouseDown={setPressedTrue}// for desktop compatibility
            onMouseUp={setPressedFalse} // for desktop compatibility
            className={`${props.className} ${props.showLoadingOverlay && 'btnLoading'}`}
            onClick={props.onClick}> 
            {props.text}
            {props.icon}
            <div
                className={`btnLoadingOverlay`}
                style={{ display: props.showLoadingOverlay ? 'flex' : 'none' }}
            >
                <span className='btnLoadingText'>Processing</span>
                <div className='loadingSpinner' />
            </div>
        </button>
    )
}

export default InteractiveBtn