// Imports
import '../styles/App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

// Pages
import Home from './Home.js'
import Contact from './Contact.js'
import Login from './Login.js'
import Register from './Register.js'
import Profile from './Profile.js'
// import Settings from './Settings.js'
import CreateGallery from './CreateGallery.js'
import EditGallery from './EditGallery.js'
import Page from './Page.js'
import CheckoutCompleted from './CheckoutCompleted.js'
import Orders from './Orders.js'
import TermsOfService from './TermsOfService.js'
import PrivacyPolicy from './PrivacyPolicy.js'

// App component
const App = () => {
    return (
        <div className='App'>
            <Router>
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route exact path='/contact' element={<Contact />} />
                    <Route exact path='/login' element={<Login />} />
                    <Route exact path='/register' element={<Register />} />
                    <Route exact path='/profile' element={<Profile />} />
                    {/* <Route exact path='/settings' element={<Settings />} /> */}
                    <Route exact path='/gallery/create' element={<CreateGallery />} />
                    <Route exact path='/gallery/edit/:galleryId' element={<EditGallery />} />
                    <Route exact path='/u/:pageId' element={<Page />} />
                    <Route exact path='/success/:transactionId' element={<CheckoutCompleted />} />
                    <Route exact path='/orders' element={<Orders />} />
                    <Route exact path='/terms-of-service' element={<TermsOfService />} />
                    <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
                </Routes>
            </Router>
        </div>
    )
}

export default App
