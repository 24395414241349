import React, { useEffect, useState } from 'react'
import HeaderBar from '../components/HeaderBar'
import Footer from '../components/Footer'
import { cropDisplayImage, cropImage } from '../mgt/imageMgt'
import { insertUserGallery, getUserDocument } from '../mgt/collectionsMgt'
import { CircleIcon, ImageIcon, PlusIcon, RemoveIcon } from '../components/icons'
import InteractiveBtn from '../components/InteractiveBtn'
import { auth } from '../mgt/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'

const CreateGallery = () => {
    const [user, loading] = useAuthState(auth)
    const [userDoc, setUserDoc] = useState(null)
    const [galleryNameInput, setGalleryNameInput] = useState('')
    const [displayImgInput, setDisplayImgInput] = useState(null)
    const [priceInput, setPriceInput] = useState('')
    const [statusInput, setStatusInput] = useState('Private')
    const [displayImageError, setDisplayImageError] = useState('')
    const [displayImageErrText, setDisplayImageErrText] = useState('')
    const [nameError, setNameError] = useState('')
    const [nameErrText, setNameErrText] = useState('')
    const [priceError, setPriceError] = useState('')
    const [priceErrText, setPriceErrText] = useState('')
    const [statusErrText, setStatusErrText] = useState('')
    const [newImages, setNewImages] = useState([])
    const [scrollAllign, setScrollAllign] = useState('')
    const navigate = useNavigate()
    const [creatingGallery, setCreatingGallery] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        (user && !loading) && getUserDoc()
    }, [user, loading])

    async function getUserDoc() {
        const userDoc = await getUserDocument(user.uid)
        setUserDoc(userDoc)
    }

    useEffect(() => {
        if (newImages.length > 0)
            setScrollAllign('')
        else
            setScrollAllign('justifyCenter')
    }, [newImages])

    useEffect(() => {
        setNameError('')
        setTimeout(() => setNameErrText(''), 1000)
    }, [galleryNameInput])

    useEffect(() => {
        setPriceError('')
        setTimeout(() => setPriceErrText(''), 1000)
    }, [priceInput])

    useEffect(() => {
        checkStatus()
    }, [newImages])

    function checkStatus() {
        if (statusInput === 'Published' && newImages.length === 0) {
            setStatusInput('Private')
        }
    }

    function inputError() {
        if (!displayImgInput) {
            setDisplayImageError('show')
            setDisplayImageErrText('Select a display image for your gallery')
            setTimeout(() => {
                setDisplayImageError('')
                setTimeout(() => setDisplayImageErrText(''), 1000)
            }, 3000)
            return true
        }

        if (galleryNameInput === '') {
            setNameError('show')
            setNameErrText('Your gallery must have a name')
            return true
        } else
            setNameError('')

        if (priceInput === '' || priceInput === '0,00' || priceInput === '0.00') {
            setPriceError('show')
            setPriceErrText('Your gallery cannot be free')
            return true
        } if (isNaN(parseFloat(priceInput))) {
            setPriceError('show')
            setPriceErrText('Enter a price for your gallery')
            setTimeout(() => setPriceError(''), 1000)
            setTimeout(() => setPriceErrText(''), 2000)
            return true
        } else {
            setPriceError('')
            setTimeout(() => setPriceErrText(''), 1000)
        }

        return false
    }

    // async function handleSelectImage(setStateFunction, push) {
    async function handleSelectImage(isDisplayImg) {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = 'image/*'

        async function handleGetFile(file) {
            if (!file) return

            if (isDisplayImg) {
                const croppedImg = await cropDisplayImage(file, 1024, 1024)
                setDisplayImgInput(croppedImg)
            } else {
                const croppedImg = await cropImage(file, 1024, 1024)
                setNewImages([croppedImg, ...newImages])
            }
        }

        input.onchange = async (e) => handleGetFile(e.target.files[0])
        input.click()
    }

    async function handleSelectNewImage() {
        // handleSelectImage(setNewImages, true)
        handleSelectImage(false)
    }

    async function handleSelectDisplayImage() {
        // handleSelectImage(setDisplayImgInput)
        handleSelectImage(true)
    }

    async function createGallery() {
        if (creatingGallery) return

        if (inputError())
            return

        setCreatingGallery(true)
        const galleryDoc = {
            name: galleryNameInput,
            price: parseFloat(priceInput).toFixed(2),
            status: statusInput,
        }
        const galleryId = await insertUserGallery(userDoc, galleryDoc, displayImgInput, newImages)
        setCreatingGallery(false)

        if (galleryId)
            navigate('/profile')
    }

    function handleFormatPrice(input) {
        let price = parseFloat(input)

        price = Math.max(0, price)
        price = Math.min(999.99, price)

        setPriceInput(price.toFixed(2))
    }

    if (loading || !user)
        return <>
            <HeaderBar />
            <div className='pageLoadingContainer'>
                <div className='loadingTxt'>Loading</div>
                <div className='loadingSpinnerBlue' />
            </div>
            <Footer />
        </>

    return (
        <>
            <HeaderBar showProfile={true} />
            <div className='createGalleryTitle'>Create a new gallery</div>
            <div className='createGallerySubTitle'>You can edit this information later</div>

            <div className='registerInputLbl'>Display image</div>
            <div className='profilePic' style={{ marginTop: '0.5rem' }}>
                {
                    displayImgInput ?
                        <div className='profilePicImage' onClick={handleSelectDisplayImage}>
                            <img src={URL.createObjectURL(displayImgInput)} alt='Profile' />
                        </div>
                        :
                        <div className='profilePicImage' onClick={handleSelectDisplayImage}>
                            <ImageIcon size={100} />
                        </div>
                }
            </div >
            <div className={`inputErrorLbl ${displayImageError}`}>{displayImageErrText}</div>

            <div className='registerInputLbl'>Gallery Name</div>
            <input
                placeholder='Name'
                className='registerInput'
                value={galleryNameInput}
                onChange={(e) => setGalleryNameInput(e.target.value)}
            />
            <div className={`inputErrorLbl ${nameError}`}>{nameErrText}</div>

            <div className='registerInputLbl'>Price (€)</div>
            <input
                type='number'
                placeholder='Price'
                className='registerInput'
                value={priceInput}
                onChange={(e) => setPriceInput(e.target.value)}
                onBlur={(e) => handleFormatPrice(e.target.value)}
            />
            <div className={`inputErrorLbl ${priceError}`}>{priceErrText}</div>

            <div className='registerInputLbl'>Content</div>
            <div className={`galleryImagesContainer ${scrollAllign}`}>
                <div className='verticalSpacer' />
                <div className='galleryImage' onClick={handleSelectNewImage}>
                    <PlusIcon size={50} />
                </div>
                {newImages?.map((img, i) => (
                    <div key={i} className='galleryImage'>
                        <div className='galleryImageRemoveIcon'>
                            <CircleIcon size={30} color='white' />
                        </div>
                        <div className='galleryImageRemoveIcon' onClick={() => setNewImages(newImages.filter((_, index) => index !== i))}>
                            <RemoveIcon size={30} />
                        </div>
                        <img src={URL.createObjectURL(img)} />
                    </div>
                ))}
                <div className='verticalSpacer' />
            </div >

            <div className='registerInputLbl'>Visibility</div>
            <div className='statusOptions'>
                <div
                    className={statusInput === 'Private' ? 'statusOptionPrivate selected' : 'statusOptionPrivate'}
                    onClick={() => setStatusInput('Private')}
                >
                    Private
                </div>
                <div
                    className={statusInput === 'Published' ? 'statusOptionPublished selected' : 'statusOptionPublished'}
                    onClick={() => {
                        if (newImages.length !== 0)
                            setStatusInput('Published')
                        else {
                            setStatusErrText('Published galleries must have content.')
                            setTimeout(() => setStatusErrText(''), 2000)
                        }
                    }}
                >
                    Published
                </div>
            </div>
            {statusErrText !== '' ?
                <div className='createGalleryInfoLbl' style={{ fontWeight: 400 }} > {statusErrText}</div >
                :
                statusInput === 'Private' ?
                    <div className='createGalleryInfoLbl'>This gallery will only be visible to you.</div>
                    :
                    <div className='createGalleryInfoLbl'>This gallery will be visible on your profile.</div>
            }

            <div className='scrollSpacer' />
            <InteractiveBtn
                text={statusInput === 'Published' ? 'Create & publish gallery' : 'Create private gallery'}
                className='loginRegisterBtn'
                onClick={createGallery}
            />
            <div className='scrollSpacer' />
            <div className='scrollSpacer' />
            <Footer />
        </>
    )
}

export default CreateGallery