// Imports:
import { auth, db } from './firebase'
import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore"
import { blurImage, deleteImgFromStorage, uploadImgToStorage } from './imageMgt'
import { getLocalStorageBuyerTransactions, getLocalStorageUser, setLocalStorageBuyerTransactions, setLocalStorageUser } from './cachingMgt'
import { generateAstroId, generateGuid } from './constants'
import { getLocalStoragePage, setLocalStoragePage } from './cachingMgt'

// Firestore functions:
const emptyUserDocument = (userId, email, name) => {
    return {
        id: userId,
        type: 'user',
        pageId: '', // generateGuid(),
        imageKey: '',
        imageFolder: generateGuid(),
        email: email,
        name: name,
        galleries: [],
        dateCreated: new Date().getTime(),
    }
}

const emptyPageDocument = (userId, name) => {
    return {
        id: userId,
        // publisherId: userId,
        name: name,
        imageKey: '',
        galleries: [],
    }
}

async function createUserDocuments(userId, email, name) {
    console.log('[FIREBASE] Creating user doc:', userId)
    try {
        const newUserDoc = emptyUserDocument(userId, email, name)
        const userDocRef = doc(db, "users", userId)
        await setDoc(userDocRef, newUserDoc)

        return newUserDoc
    } catch (error) {
        console.log('Error creating user doc:', error)
        return false
    }
}

async function getUserDocument(userId, customDisplayName) {
    try {
        const user = await getLocalStorageUser()
        if (user) {
            return user
        }

        console.log('[FIREBASE] Getting user doc:', userId)
        const pointer = doc(db, "users", userId)
        const snapshot = await getDoc(pointer)
        const name = customDisplayName ? customDisplayName : auth.currentUser.displayName
        const docData = snapshot.exists() ? snapshot.data() : await createUserDocuments(userId, auth.currentUser.email, name)
        setLocalStorageUser(docData)
        return docData

    } catch (error) {
        console.log('Error getting user doc:', error)
        return false
    }
}

async function modifyUserDocument(userDoc) {
    try {
        console.log('[FIREBASE] Updating user doc:', userDoc.id)
        const pointer = doc(db, "users", userDoc.id)
        await setDoc(pointer, userDoc)
        setLocalStorageUser(userDoc)
        return userDoc
    } catch (error) {
        console.log('Error updating user doc:', error)
        return false
    }
}

async function getBuyerTransactionDocuments(userId) {
    // transaction documents not used anymore
    // return []

    try {
        const cachedTransactions = getLocalStorageBuyerTransactions()
        if (cachedTransactions) {
            console.log('Cached buyer transactions:', cachedTransactions)
            return cachedTransactions
        }

        console.log('[FIREBASE] Getting buyer transaction documents for:', userId)
        const transactions = []
        const pointer = collection(db, "transactions")
        const q = query(pointer, where("buyerId", "==", userId))
        const snapshot = await getDocs(q)

        snapshot.forEach((doc) => {
            transactions.push(doc.data())
        })
        transactions.sort((a, b) => b.timestamp - a.timestamp)

        setLocalStorageBuyerTransactions(transactions)

        return transactions
    } catch (error) {
        console.log('Error getting transaction docs:', error)
        return false
    }
}

async function getTransactionDoc(docId) {
    try {
        console.log('[FIREBASE] Getting transaction doc:', docId)
        const pointer = doc(db, "transactions", docId)
        const snapshot = await getDoc(pointer)
        return snapshot.exists() ? snapshot.data() : false
    } catch (error) {
        console.log('Error getting transaction doc:', error)
        return false
    }
}

async function insertUserGallery(userDoc, newGalleryDoc, displayImg, images) {
    console.log('[FIREBASE] Creating gallery.')

    function getImagePath(imageKey) {
        return `${userDoc.imageFolder}/${imageKey}`
    }

    try {
        newGalleryDoc.id = generateGuid()

        const displayImgKey = generateAstroId()
        await uploadImgToStorage(displayImg, getImagePath(displayImgKey))
        newGalleryDoc.imageKey = displayImgKey

        newGalleryDoc.images = await Promise.all(
            images.map(async (image) => {
                const imageKey = generateAstroId()
                await uploadImgToStorage(image, getImagePath(imageKey))

                const previewKey = generateAstroId()
                const blurredImage = await blurImage(image)
                await uploadImgToStorage(blurredImage, getImagePath(previewKey))

                return { imageKey, previewKey }
            })
        )

        userDoc.galleries.unshift(newGalleryDoc)
        await modifyUserDocument(userDoc)

        await syncPageDocument(userDoc)

        return newGalleryDoc.id
    } catch (error) {
        console.log('Error creating gallery:', error)
        return false
    }
}

async function modifyUserGallery(userDoc, updatedGalleryDoc, displayImg, images) {
    console.log('[FIREBASE] Editing gallery.')

    function getImagePath(imageKey) {
        return `${userDoc.imageFolder}/${imageKey}`
    }

    try {
        const galleryIndex = userDoc.galleries.findIndex((gallery) => gallery.id === updatedGalleryDoc.id)

        if (typeof displayImg === 'object') {
            console.log('Deleting old display image:', updatedGalleryDoc.imageKey)
            await deleteImgFromStorage(getImagePath(updatedGalleryDoc.imageKey))

            console.log('Uploading new display image:', displayImg)
            const displayImgKey = generateAstroId()
            await uploadImgToStorage(displayImg, getImagePath(displayImgKey))
            updatedGalleryDoc.imageKey = displayImgKey
        }

        const keptImages = await Promise.all(
            updatedGalleryDoc.images.map(async (image) => {
                if (image?.toDelete === true) {
                    console.log('Deleting image:', image)
                    await deleteImgFromStorage(getImagePath(image.imageKey))
                    await deleteImgFromStorage(getImagePath(image.previewKey))
                    return 'deleted'
                } else
                    return image
            })
        ).then((images) => images.filter((image) => (image !== 'deleted')))

        const newImages = await Promise.all(
            images.filter((image) => (typeof image === 'object'))
                .map(async (image) => {
                    console.log('Uploading new image:', image)
                    const imageKey = generateAstroId()
                    await uploadImgToStorage(image, getImagePath(imageKey))

                    const previewKey = generateAstroId()
                    const blurredImage = await blurImage(image)
                    await uploadImgToStorage(blurredImage, getImagePath(previewKey))

                    return { imageKey, previewKey }
                })
        )

        updatedGalleryDoc.images = [...newImages, ...keptImages]

        userDoc.galleries[galleryIndex] = updatedGalleryDoc
        await modifyUserDocument(userDoc)

        await syncPageDocument(userDoc)

        return true
    } catch (error) {
        console.log('Error editing gallery:', error)
        return false
    }
}

async function syncPageDocument(userDoc) {
    try {
        if (userDoc.type === 'user')
            return false

        console.log('[FIREBASE] Syncing page doc for user:', userDoc.id)

        let pageDoc = emptyPageDocument(userDoc.id, userDoc.name)
        pageDoc.name = userDoc.name
        pageDoc.bio = userDoc.bio || ''
        pageDoc.imageFolder = userDoc.imageFolder
        pageDoc.imageKey = userDoc.imageKey
        pageDoc.galleries = userDoc.galleries
            .filter((gallery) => gallery.status === 'Published')
            .map((gallery) => {
                return {
                    id: gallery.id,
                    publisherId: userDoc.id,
                    name: gallery.name,
                    price: gallery.price,
                    imageKey: gallery.imageKey,
                    previewKeys: gallery.images.map((image) => image.previewKey),
                }
            })

        const pointer = doc(db, "pages", userDoc.id)
        await setDoc(pointer, pageDoc)
        setLocalStoragePage(pageDoc)
        return true
    } catch (error) {
        console.log('Error syncing page doc:', error)
        return false
    }
}

async function getPageDocument(pageId) {
    try {
        const page = getLocalStoragePage(pageId)
        if (page) {
            return page
        }

        console.log('[FIREBASE] Getting page doc:', pageId)
        const pointer = doc(db, "pages", pageId)
        const snapshot = await getDoc(pointer)
        const docData = snapshot.exists() ? snapshot.data() : false
        setLocalStoragePage(docData)
        return docData
    } catch (error) {
        console.log('Error getting page doc:', error)
        return false
    }
}

async function insertMessage(accessKey, message) {
    try {
        const newMessage = {
            ...message,
            date: new Date().getTime()
        }

        console.log('[FIREBASE] Inserting message:', message)
        const pointer = doc(db, "messages", accessKey)
        var messagesDoc = await getDoc(pointer)

        if (messagesDoc.exists()) {
            messagesDoc = messagesDoc.data()
            messagesDoc.messages = messagesDoc.messages ? messagesDoc.messages : []
        } else {
            messagesDoc = { messages: [] }
        }

        if (messagesDoc.messages.length > 10) {
            return { success: false, msg: 'Message limit reached, please wait for a response.' }
        }
        messagesDoc.messages.push(newMessage)

        await setDoc(pointer, messagesDoc)

        return { success: true, msg: 'Message sent!' }
    } catch (error) {
        console.log('Error inserting message:', error)
        return { success: false, msg: 'Something went wrong. Please try again later.' }
    }
}

export {
    getUserDocument,
    modifyUserDocument,
    getBuyerTransactionDocuments,
    getTransactionDoc,
    insertUserGallery,
    modifyUserGallery,
    syncPageDocument,
    getPageDocument,
    insertMessage,
}