import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../mgt/firebase';
import { getBuyerTransactionDocuments, getUserDocument, modifyUserDocument, syncPageDocument } from '../mgt/collectionsMgt';
import '../styles/App.css'
import InteractiveBtn from '../components/InteractiveBtn';
import { CheckIcon, ImageIcon, LoadingIcon, ProfileHalfIcon } from '../components/icons';
import HeaderBar from '../components/HeaderBar';
import Footer from '../components/Footer';
import { cropImage, getDownloadURLFromPath, uploadImgToStorage } from '../mgt/imageMgt';
import GalleryCard from '../components/GalleryCard';
import { handleSignOut } from '../mgt/authMgt';

const Profile = () => {
    const [user, loading] = useAuthState(auth)
    const [userDoc, setUserDoc] = useState(null)
    const navigate = useNavigate();
    const [displayNameInput, setDisplayNameInput] = useState('')
    const [yourBioInput, setYourBioInput] = useState('')
    const [updatingDisplayName, setUpdatingDisplayName] = useState(false)
    const [updatingBio, setUpdatingBio] = useState(false)
    const [profilePicUrl, setProfilePicUrl] = useState('')
    const [copyLinkTxt, setCopyLinkTxt] = useState('Share')
    const [buyerTransactions, setBuyerTransactions] = useState(null)
    const [nameInputLblFocusClass, setNameInputLblFocusClass] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (loading)
            return

        if (!user && !loading) {
            goToLogin()
        }

        if (user && !loading) {
            getUserDoc()
            getProfilePicUrl()
        }
    }, [user, loading])

    function goToLogin() {
        navigate('/login')
    }

    function goToOrders() {
        navigate('/orders')
    }

    async function getUserDoc() {
        const userDoc = await getUserDocument(user.uid)
        setUserDoc(userDoc)

        getBuyerTransactions()
    }

    async function getBuyerTransactions() {
        const transactions = await getBuyerTransactionDocuments(user.uid)
        setBuyerTransactions(transactions)
    }

    useEffect(() => {
        if (userDoc) {
            setDisplayNameInput(userDoc.name)
            getProfilePicUrl()
            setYourBioInput(userDoc.bio)
        }
    }, [userDoc])

    async function getProfilePicUrl() {
        if (!userDoc?.imageKey)
            return

        const downloadUrl = await getDownloadURLFromPath(`profilePictures/${userDoc.id}`)
        setProfilePicUrl(downloadUrl)
    }

    async function trySaveDisplayName() {
        if (userDoc?.name?.trim() === displayNameInput || displayNameInput?.trim() === '')
            return

        setUpdatingDisplayName(true)
        var newUserDoc = { ...userDoc }
        newUserDoc.name = displayNameInput.trim()
        await modifyUserDocument(newUserDoc)
        await syncPageDocument(newUserDoc)
        setUserDoc(newUserDoc)
        setUpdatingDisplayName(false)
    }

    async function trySaveBio() {
        if (userDoc?.bio?.trim() === yourBioInput)
            return

        setUpdatingBio(true)
        var newUserDoc = { ...userDoc }
        newUserDoc.bio = yourBioInput.trim()
        await modifyUserDocument(newUserDoc)
        await syncPageDocument(newUserDoc)
        setUserDoc(newUserDoc)
        setUpdatingBio(false)
    }

    function insertUserGallery() {
        navigate('/gallery/create')
    }

    function handlePreviewPage() {
        navigate('/u/' + userDoc.id)
    }

    function handleNameFocus() {
        setNameInputLblFocusClass('inputLblFocus')
    }

    function handleNameBlur() {
        setNameInputLblFocusClass('')
    }

    async function handleChangeProfilePic() {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = 'image/*'

        const firebasePath = `profilePictures/${userDoc.id}`

        async function handleGetFile(file) {
            if (!file)
                return

            const croppedImg = await cropImage(file, 500, 500)
            const imageKey = await uploadImgToStorage(croppedImg, firebasePath)

            const newUserDoc = { ...userDoc }
            newUserDoc.imageKey = imageKey
            await modifyUserDocument(newUserDoc)
            setUserDoc(newUserDoc)

            const downloadUrl = await getDownloadURLFromPath(firebasePath)
            setProfilePicUrl(downloadUrl)
        }

        input.onchange = async (e) => handleGetFile(e.target.files[0])
        input.click()
    }

    async function copyPageLinkToClipboard() {
        try {
            const pageLink = window.location.origin + '/u/' + userDoc.id
            await navigator.clipboard.writeText(pageLink)
            setCopyLinkTxt('Copied!')
            setTimeout(() => setCopyLinkTxt('Share'), 2000)
        } catch (error) {
            console.error('Failed to copy: ', error)
        }
    }

    function handleBlurDisplayName() {
        if (displayNameInput.trim() === '')
            setDisplayNameInput(userDoc?.name)
    }

    async function signOut() {
        await handleSignOut()
        navigate('/')
    }

    if (loading || !userDoc)
        return <>
            <HeaderBar />
            <div className='pageLoadingContainer'>
                <div className='loadingTxt'>Loading</div>
                <div className='loadingSpinnerBlue' />
            </div>
            <Footer />
        </>

    return (
        <>
            <HeaderBar showSettings={true} showContact={true} />

            {userDoc?.type === 'seller' ?
                <>
                    <div className='profilePic'>
                        {profilePicUrl ?
                            <div className='profilePicImage' onClick={handleChangeProfilePic}>
                                <img src={profilePicUrl} />
                            </div>
                            :
                            <>
                                <div className='profilePicImage' onClick={handleChangeProfilePic}>
                                    <ProfileHalfIcon size={300} />
                                </div>
                                <div className='profilePicEdit' onClick={handleChangeProfilePic}>
                                    <ImageIcon size={30} />
                                </div>
                            </>
                        }
                    </div>
                    <div
                        className='changeProfilePicBtn'
                        onClick={handleChangeProfilePic}
                    >
                        {profilePicUrl ? 'Change' : 'Add'} profile picture
                    </div>
                </> : <>
                    {/* <div className='profileTitle'>Profile</div> */}
                    <div>Profile</div>
                </>
            }

            <div className={'registerInputLbl profileInputLbl ' + nameInputLblFocusClass} style={{}}>Name</div>
            <div className='profileNameWrapper'>
                <input
                    value={displayNameInput}
                    type='text'
                    placeholder='Your name'
                    className='profileNameInput'
                    maxLength={25}
                    onChange={(e) => setDisplayNameInput(e.target.value)}
                    onBlur={() => { handleNameBlur(); handleBlurDisplayName() }}
                    onFocus={handleNameFocus}
                />
                {(userDoc?.name !== displayNameInput) &&
                    <>
                        {
                            updatingDisplayName ?
                                <div className='showPasswordIcon spinning'>
                                    <LoadingIcon size={30} />
                                </div>
                                :
                                <div className='showPasswordIcon' onClick={trySaveDisplayName}>
                                    <CheckIcon size={30} />
                                </div>
                        }
                    </>
                }
            </div>


            {userDoc?.type === 'seller' && <>
                <div className='registerInputLbl' style={{ fontSize: '1rem', marginBottom: 0, fontWeight: '200' }}>Bio</div>
                <div className='profileNameWrapper'>
                    <input
                        value={yourBioInput}
                        type='text'
                        placeholder='Your bio'
                        className='profileNameInput'
                        maxLength={200}
                        onChange={(e) => setYourBioInput(e.target.value)}
                        style={{
                            paddingRight: (userDoc?.bio !== yourBioInput) ? '3rem' : '0.5rem',
                            width: (userDoc?.bio !== yourBioInput) && 'calc(100% - 6.5rem)' // 3rem for icon, 4rem for padding
                        }}
                    />
                    {(userDoc?.bio !== yourBioInput) &&
                        <>
                            {
                                updatingBio ?
                                    <div className='showPasswordIcon spinning'>
                                        <LoadingIcon size={30} />
                                    </div>
                                    :
                                    <div className='showPasswordIcon' onClick={trySaveBio}>
                                        <CheckIcon size={30} />
                                    </div>
                            }
                        </>
                    }
                </div>
            </>}

            {userDoc?.type === 'seller' && <>
                <div className='scrollSpacer' />
                {userDoc?.galleries.filter(gallery => gallery.status === 'Published').length > 0 &&
                    <>
                        <div className='myGalleriesHdr'>
                            <div className='myGalleryTitle'>Published Galleries</div>
                            <InteractiveBtn
                                className='addGalleryBtn'
                                text={copyLinkTxt}
                                onClick={copyPageLinkToClipboard}
                            />
                        </div>
                        <div className='myGalleriesBody'>
                            <InteractiveBtn
                                className='previewPageBtn'
                                text='View your store page'
                                onClick={handlePreviewPage}
                                stretch={true}
                            />
                        </div>
                        <div className='scrollSpacer' />
                    </>
                }

                <div className='myGalleriesHdr'>
                    <div className='myGalleryTitle'>My Galleries</div>
                    {
                        userDoc?.galleries.length > 0 &&
                        <InteractiveBtn
                            className='addGalleryBtn'
                            text='Create'
                            onClick={insertUserGallery}
                        />
                    }
                </div>
                <div className='myGalleriesBody'>
                    {userDoc?.galleries.length > 0 ?
                        <>
                            <div className='scrollSpacerSmall' />
                            {userDoc?.galleries
                                ?.sort((a, b) => a.sortNo - b.sortNo)
                                ?.map((gallery, index) => {
                                    return (
                                        <GalleryCard
                                            key={index}
                                            id={gallery.id}
                                            imagePath={userDoc.imageFolder + '/' + gallery.imageKey}
                                            name={gallery.name}
                                            price={gallery.price}
                                            status={gallery.status}
                                        />
                                    )
                                })
                            }
                        </>
                        :
                        <InteractiveBtn
                            className='loginRegisterBtn'
                            text='Create your first gallery'
                            onClick={insertUserGallery}
                        />
                    }
                </div>
                <div className='scrollSpacer' />
            </>}

            <div className='scrollSpacer' />
            <div className='myGalleriesHdr'>
                <div className='myGalleryTitle'>Order history</div>
            </div>
            {buyerTransactions?.length > 0 ?
                <>
                    <div className='myGalleriesBody'>
                        <InteractiveBtn
                            className='previewPageBtn'
                            text='View galleries'
                            onClick={goToOrders}
                            stretch={true}
                        />
                    </div>
                </>
                :
                <div className='myGalleriesBody'>
                    <div className='scrollSpacerSmall' />
                    <div className='createGalleryInfoLbl'>Your revealed galleries will be shown here.</div>
                </div>
            }

            <div className='scrollSpacer' />
            <div className='scrollSpacer' />
            <InteractiveBtn
                className='marginTopAuto loginRegisterBtn logoutBtn'
                text='Log out'
                onClick={signOut}
            />
            <div className='scrollSpacer' />
            <div className='scrollSpacer' />
            <Footer />
        </>
    )
}

export default Profile