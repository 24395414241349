import React, { useEffect, useState } from 'react'
import HeaderBar from '../components/HeaderBar'
import Footer from '../components/Footer'
import { cropImage, deleteImgFromStorage, getDownloadURLFromPath } from '../mgt/imageMgt'
import { modifyUserGallery, getUserDocument, modifyUserDocument, syncPageDocument } from '../mgt/collectionsMgt'
import { CircleIcon, ImageIcon, PlusIcon, RemoveIcon } from '../components/icons'
import InteractiveBtn from '../components/InteractiveBtn'
import { auth } from '../mgt/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate, useParams } from 'react-router-dom'

const EditGallery = () => {
    const { galleryId } = useParams()
    const [user, loading] = useAuthState(auth)
    const [userDoc, setUserDoc] = useState(null)
    const [galleryDoc, setGalleryDoc] = useState(null)
    const [galleryNameInput, setGalleryNameInput] = useState('')
    const [displayImg, setDisplayImg] = useState(null)
    const [priceInput, setPriceInput] = useState('')
    const [statusInput, setStatusInput] = useState('Private')
    const [displayImageError, setDisplayImageError] = useState('')
    const [displayImageErrText, setDisplayImageErrText] = useState('')
    const [nameError, setNameError] = useState('')
    const [nameErrText, setNameErrText] = useState('')
    const [priceError, setPriceError] = useState('')
    const [priceErrText, setPriceErrText] = useState('')
    const [statusErrText, setStatusErrText] = useState('')
    const [galleryImages, setGalleryImages] = useState([])
    const [scrollAllign, setScrollAllign] = useState('')
    const navigate = useNavigate()
    const [changed, setChanged] = useState(false)
    const [imgChanged, setImgChanged] = useState(false)
    const nameChanged = () => { return galleryNameInput !== galleryDoc?.name }
    const priceChanged = () => { return priceInput !== galleryDoc?.price }
    const statusChanged = () => { return statusInput !== galleryDoc?.status }
    const [editingGallery, setEditingGallery] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        (user && !loading) && loadGallery()
    }, [user, loading])

    async function loadGallery() {
        const userDoc = await getUserDocument(user.uid)
        setUserDoc(userDoc)

        const editGalleryDoc = userDoc.galleries.find(gallery => gallery.id === galleryId)
        setGalleryDoc(editGalleryDoc)

        setGalleryNameInput(editGalleryDoc?.name)
        setPriceInput(editGalleryDoc?.price)
        setStatusInput(editGalleryDoc?.status)

        const displayImgUrl = await getDownloadURLFromPath(`${userDoc.imageFolder}/${editGalleryDoc?.imageKey}`)
        setDisplayImg(displayImgUrl)

        const newImagesUrls = await Promise.all(editGalleryDoc?.images.map(async img => {
            return await getDownloadURLFromPath(`${userDoc.imageFolder}/${img.imageKey}`)
        }))
        setGalleryImages(newImagesUrls)
    }

    useEffect(() => {
        if (galleryImages.length > 0)
            setScrollAllign('')
        else
            setScrollAllign('justifyCenter')
    }, [galleryImages])

    useEffect(() => {
        setNameError('')
        setTimeout(() => setNameErrText(''), 1000)
    }, [galleryNameInput])

    useEffect(() => {
        setPriceError('')
        setTimeout(() => setPriceErrText(''), 1000)
    }, [priceInput])

    useEffect(() => {
        checkStatus()
    }, [galleryImages])

    useEffect(() => {
        checkIsChanged()
    }, [galleryNameInput, priceInput, statusInput])

    function checkStatus() {
        if (statusInput === 'Published' && galleryImages.length === 0) {
            setStatusInput('Private')
        }
    }

    function checkIsChanged() {
        if (!galleryDoc) return
        if (imgChanged) {
            setChanged(true)
            return
        }
        const isChanged = nameChanged() || priceChanged() || statusChanged()
        setChanged(isChanged)
    }

    function inputError() {
        if (!displayImg) {
            setDisplayImageError('show')
            setDisplayImageErrText('Select a display image for your gallery')
            setTimeout(() => {
                setDisplayImageError('')
                setTimeout(() => setDisplayImageErrText(''), 1000)
            }, 3000)
            return true
        }

        if (galleryNameInput === '') {
            setNameError('show')
            setNameErrText('Your gallery must have a name')
            return true
        } else
            setNameError('')

        if (priceInput === '' || priceInput === '0,00' || priceInput === '0.00') {
            setPriceError('show')
            setPriceErrText('Your gallery cannot be free')
            return true
        } if (isNaN(parseFloat(priceInput))) {
            setPriceError('show')
            setPriceErrText('Enter a price for your gallery')
            setTimeout(() => setPriceError(''), 1000)
            setTimeout(() => setPriceErrText(''), 2000)
            return true
        } else {
            setPriceError('')
            setTimeout(() => setPriceErrText(''), 1000)
        }

        return false
    }

    async function handleSelectDisplayImage() {
        handleSelectImage(true)
    }

    async function handleSelectNewImage() {
        handleSelectImage(false)
    }

    async function handleSelectImage(replace) {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = 'image/*'

        async function handleGetFile(file) {
            if (!file) return

            setImgChanged(true)
            setChanged(true)

            const croppedImg = await cropImage(file, 1024, 1024)
            if (!replace)
                setGalleryImages([croppedImg, ...galleryImages])
            else
                setDisplayImg(croppedImg)
        }

        input.onchange = async (e) => handleGetFile(e.target.files[0])
        input.click()
    }

    async function editGallery() {
        if (editingGallery) return

        if (inputError())
            return

        setEditingGallery(true)
        const updatedGalleryDoc = { ...galleryDoc }
        updatedGalleryDoc.name = galleryNameInput
        updatedGalleryDoc.price = priceInput
        updatedGalleryDoc.status = statusInput

        const result = await modifyUserGallery(userDoc, updatedGalleryDoc, displayImg, galleryImages)
        setEditingGallery(false)

        if (result)
            navigate('/profile')
    }

    async function handleRemoveImage(index) {
        if (typeof galleryImages[index] === 'string') {
            // Set "toDelete" key to true on the object in the images array
            setGalleryDoc({
                ...galleryDoc, images: galleryDoc.images.map((img, i) => {
                    if (i === index)
                        return { ...img, toDelete: true }
                    return img
                })
            })
        }

        const updatedImages = [...galleryImages]
        updatedImages.splice(index, 1)

        setGalleryImages(updatedImages)
        setImgChanged(true)
        setChanged(true)
    }

    async function deleteGallery() {
        if (window.confirm('Are you sure you want to delete this gallery?\nThis action cannot be undone!')) {

            await deleteImgFromStorage(`${userDoc.imageFolder}/${galleryDoc?.imageKey}`)

            new Promise((resolve) => {
                galleryDoc.images.forEach(async (img) => {
                    await deleteImgFromStorage(`${userDoc.imageFolder}/${img.imageKey}`)
                    await deleteImgFromStorage(`${userDoc.imageFolder}/${img.previewKey}`)
                    resolve()
                })
            })

            const newUserDoc = { ...userDoc }
            newUserDoc.galleries = newUserDoc.galleries.filter(gallery => gallery.id !== galleryId)
            await modifyUserDocument(newUserDoc)

            if (galleryDoc.status === 'Published')
                await syncPageDocument(newUserDoc)

            navigate('/profile')
        }
    }

    function handleFormatPrice(input) {
        let price = parseFloat(input)

        price = Math.max(0, price)
        price = Math.min(999.99, price)

        setPriceInput(price.toFixed(2))
    }

    function goToProfile() {
        navigate('/profile')
    }

    if (loading || !user)
        return <>
            <HeaderBar />
            <div className='pageLoadingContainer'>
                <div className='loadingTxt'>Loading</div>
                <div className='loadingSpinnerBlue' />
            </div>
            <Footer />
        </>

    return (
        <>
            <HeaderBar showProfile={true} />
            <div className='editGalleryTitle'>Edit your gallery</div>
            {
                galleryDoc?.status === 'Published' ?
                    <div className='myGalleryCardStatusPublished'>Published</div>
                    :
                    <div className='myGalleryCardStatusPrivate'>Private</div>
            }

            <div className='registerInputLbl'>Display image</div>
            <div className='profilePic' style={{ marginTop: '0.5rem' }}>
                {
                    displayImg ?
                        <div className='profilePicImage' onClick={handleSelectDisplayImage}>
                            {typeof displayImg === 'string' ?
                                <img src={displayImg} />
                                :
                                <img src={URL.createObjectURL(displayImg)} />
                            }
                        </div>
                        :
                        <div className='profilePicImage' onClick={handleSelectDisplayImage}>
                            <ImageIcon size={100} />
                        </div>
                }
            </div >
            <div className={`inputErrorLbl ${displayImageError}`}>{displayImageErrText}</div>

            <div className='registerInputLbl'>Gallery Name</div>
            <input
                placeholder='Name'
                className='registerInput'
                value={galleryNameInput}
                onChange={(e) => setGalleryNameInput(e.target.value)}
            />
            <div className={`inputErrorLbl ${nameError}`}>{nameErrText}</div>

            <div className='registerInputLbl'>Price (€)</div>
            <input
                type='number'
                placeholder='Price'
                className='registerInput'
                value={priceInput}
                onChange={(e) => setPriceInput(e.target.value)}
                onBlur={(e) => handleFormatPrice(e.target.value)}
            />
            <div className={`inputErrorLbl ${priceError}`}>{priceErrText}</div>

            <div className='registerInputLbl'>Content</div>
            <div className={`galleryImagesContainer ${scrollAllign}`}>
                <div className='verticalSpacer' />
                <div className='galleryImage' onClick={handleSelectNewImage}>
                    <PlusIcon size={50} />
                </div>
                {galleryImages?.map((img, i) => (
                    <div key={i} className='galleryImage'>
                        <div className='galleryImageRemoveIcon'>
                            <CircleIcon size={30} color='white' />
                        </div>
                        <div className='galleryImageRemoveIcon'
                            onClick={() => handleRemoveImage(i)}>
                            <RemoveIcon size={30} />
                        </div>
                        {typeof img === 'string' ?
                            <img src={img} />
                            :
                            <img src={URL.createObjectURL(img)} />
                        }
                    </div>
                ))}
                <div className='verticalSpacer' />
            </div >

            <div className='registerInputLbl'>Visibility</div>
            <div className='statusOptions'>
                <div
                    className={statusInput === 'Private' ? 'statusOptionPrivate selected' : 'statusOptionPrivate'}
                    onClick={() => setStatusInput('Private')}
                >
                    Private
                </div>
                <div
                    className={statusInput === 'Published' ? 'statusOptionPublished selected' : 'statusOptionPublished'}
                    onClick={() => {
                        if (galleryImages.length !== 0)
                            setStatusInput('Published')
                        else {
                            setStatusErrText('Published galleries must have content.')
                            setTimeout(() => setStatusErrText(''), 2000)
                        }
                    }}
                >
                    Published
                </div>
            </div>
            {statusErrText !== '' ?
                <div className='createGalleryInfoLbl' style={{ fontWeight: 400 }} > {statusErrText}</div >
                :
                statusInput === 'Private' ?
                    <div className='createGalleryInfoLbl'>This gallery will only be visible to you.</div>
                    :
                    <div className='createGalleryInfoLbl'>This gallery will be visible on your profile.</div>
            }

            <div className='scrollSpacer' />
            <InteractiveBtn
                text={!changed ? 'Back' : statusInput === 'Published' ? 'Save & publish gallery' : 'Save private gallery'}
                className={`loginRegisterBtn ${!changed && 'inactiveColor'}`}
                onClick={!changed ? goToProfile : editGallery}
            />
            <InteractiveBtn
                text='Delete'
                className='loginRegisterBtn warningColor'
                onClick={deleteGallery}
            />
            <div className='scrollSpacer' />
            <div className='scrollSpacer' />
            <Footer />
        </>
    )
}

export default EditGallery