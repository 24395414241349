import React from 'react'

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footerText'>© 2024 reveell.me - All rights reserved</div>
            <div className='footerText'>All payments are processed securely by Stripe.</div>
        </div>
    )
}

export default Footer