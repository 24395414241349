import React, { useEffect, useState } from 'react'
import HeaderBar from '../components/HeaderBar'
import Footer from '../components/Footer'
import InteractiveBtn from '../components/InteractiveBtn'
import { getAccessKey } from '../mgt/cachingMgt'
import { insertMessage } from '../mgt/collectionsMgt'

const messageTime = 3000

const Contact = () => {
    const [emailInput, setEmailInput] = useState('')
    const [emailError, setEmailError] = useState('')
    const [emailErrText, setEmailErrText] = useState('')
    const [messageInput, setMessageInput] = useState('')
    const [messageError, setMessageError] = useState('')
    const [messageErrText, setMessageErrText] = useState('')
    const [messageSuccessClass, setMessageSuccessClass] = useState('')
    const [sendingMessage, setSendingMessage] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const inputsOk = () => {
        return checkEmailInput() && checkMessageInput()
    }

    function setEmailErrorLbl(error) {
        setEmailErrText(error)
        setEmailError('show')
        setTimeout(() => {
            setEmailError('')
            setTimeout(() => { setEmailErrText('') }, messageTime / 3)
        }, messageTime)
    }

    function setMessageErrorLbl(error) {
        setMessageErrText(error)
        setMessageError('show')
        setTimeout(() => {
            setMessageError('')
            setTimeout(() => { setMessageErrText('') }, messageTime / 3)
        }, messageTime)
    }

    function setMessageSuccessLbl(text) {
        setMessageErrText(text)
        setMessageError('show')
        setMessageSuccessClass('successLbl')
        setTimeout(() => {
            setMessageError('')
            setTimeout(() => { setMessageErrText(''); setMessageSuccessClass('') }, messageTime / 3)
        }, messageTime)
    }

    function checkEmailInput() {
        if (emailInput === '') {
            setEmailErrorLbl('Please enter your email')
            return false
        }

        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailRegex.test(emailInput)) {
            setEmailErrorLbl('Please enter a valid email address')
            return false
        }

        return true
    }

    function checkMessageInput() {
        if (messageInput === '') {
            setMessageErrorLbl('Please enter a message')
            return false
        }
        return true
    }

    function clearErrorLbls() {
        setEmailError('')
        setEmailErrText('')
        setMessageError('')
        setMessageErrText('')
    }

    async function sendMessage() {
        clearErrorLbls()
        if (!inputsOk()) return

        if (sendingMessage) return
        setSendingMessage(true)

        const accessKey = getAccessKey()
        console.log('Access key:', accessKey)

        const newMessage = {
            email: emailInput,
            message: messageInput
        }

        const result = await insertMessage(accessKey, newMessage)

        if (result.success) {
            setMessageSuccessLbl(result.msg)
            setEmailInput('')
            setMessageInput('')
        } else {
            setMessageErrorLbl(result.msg)
        }

        setSendingMessage(false)
    }

    return (
        <>
            <HeaderBar showHome={true} />
            <p className='heroText' style={{ marginTop: '2rem' }}>Let's get in touch</p>
            <p className='heroSubText' style={{ fontWeight: '400', paddingLeft: '1rem', paddingRight: '1rem', marginBottom: '0.1rem' }}>Do you have any questions?</p>
            <p className='heroSubText' style={{ fontSize: '15pt', fontWeight: '200', marginBottom: '1rem', paddingRight: '1rem', paddingLeft: '1rem' }}>Let us know and we'll get back to you as soon as possible!</p>
            <div className='contactForm'>
                <input
                    placeholder='Your email'
                    className='registerInput'
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                    onBlur={checkEmailInput}
                />
                <div className={`inputErrorLbl ${emailError}`} style={{ marginTop: '0.5rem' }}>{emailErrText}</div>
                <textarea
                    placeholder='Message'
                    className='registerInput contactInputMessage'
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                />
                <div className={`inputErrorLbl ${messageSuccessClass} ${messageError}`} style={{ marginTop: '0.5rem' }}>{messageErrText}</div>
                <InteractiveBtn text='Send' className='loginRegisterBtn' onClick={sendMessage} />
            </div>
            <Footer />
        </>
    )
}

export default Contact