import { v4 as uuidv4 } from 'uuid'
import CryptoJS from 'crypto-js'

const authExpirationHours = 24
const localStorageExpirationMinutes = 5
const pageRefreshRateInMinutes = 2
const localStoragePageKey = (pageId => `pageDoc-${pageId}`)
const AstroIdCharacters = 'abcdefghijklmnopqrstuvwxyz0123456789'
function generateGuid() { return uuidv4() }
function generateAstroId() {
    const charactersLength = AstroIdCharacters.length
    return Array.from({ length: 50 }, () => {
        const randomByte = CryptoJS.lib.WordArray.random(1)
        const charIndex = randomByte.words[0] % charactersLength
        return AstroIdCharacters.charAt(charIndex)
    }).join('')
}
const localStorageKeys = {
    userDoc: 'userDoc',
    buyerTransactions: 'buyerTransactions',
    pageDoc: 'pageDoc',
    accessKey: 'accessKey',
}

export {
    authExpirationHours,
    localStorageExpirationMinutes,
    pageRefreshRateInMinutes,
    localStoragePageKey,
    AstroIdCharacters,
    generateGuid,
    generateAstroId,
    localStorageKeys,
}