import { initializeApp } from "firebase/app"
import { GoogleAuthProvider, getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { getFunctions, httpsCallable } from "firebase/functions"

// App configuration
const app = initializeApp({
    apiKey: "AIzaSyADGqoTK_Mza_KX4iGIVdOs5OSbu9dgN_c",
    authDomain: "reveell-me.firebaseapp.com",
    projectId: "reveell-me",
    storageBucket: "reveell-me.appspot.com",
    messagingSenderId: "595870267540",
    appId: "1:595870267540:web:af58c06656d6d073e55c81"
})

// Firebase services
export const auth = getAuth(app)
export const googleProvider = new GoogleAuthProvider()
export const db = getFirestore()
export const storage = getStorage(app)
export const functions = getFunctions(app)

// Cloud Functions
export const testReveellGalleryWithStripe = httpsCallable(functions, 'testReveellGalleryWithStripe')
export const testStripeCheckoutCreate = httpsCallable(functions, 'testStripeCheckoutCreate')