import React from 'react'
import { RevealIcon, ProfileIcon, EmailIcon, SettingsIcon, HomeIcon } from './icons'
import { useNavigate } from 'react-router-dom'

const HeaderBar = (props) => {
    const { showSettings, showContact, showProfile, showHome } = props
    const navigate = useNavigate()

    function handleNavigateHome() {
        navigate('/')
    }

    function handleNavigateProfile() {
        navigate('/profile')
    }

    function handleContactUs() {
        navigate('/contact')
    }

    function handleGoToSettings() {
        navigate('/settings')
    }

    return (
        <div className='headerBar'>
            <div className='headerBarLeft' onClick={handleNavigateHome}>
                <p>Reveell</p>
                <div className='headerBarLogo'>
                    <RevealIcon size={45} transparent={true} />
                </div>
                <p>me</p>
            </div>
            {showContact &&
                <div className='headerBarContact' onClick={handleContactUs}>
                    <EmailIcon size={45} />
                </div>
            }
            {showProfile &&
                <div className='headerBarProfile' onClick={handleNavigateProfile}>
                    <ProfileIcon size={45} />
                </div>
            }
            {showSettings && false &&
                <div className='headerBarProfile' onClick={handleGoToSettings}>
                    <SettingsIcon size={45} />
                </div>
            }
            {showHome &&
                <div className='headerBarProfile' onClick={handleNavigateHome}>
                    <HomeIcon size={45} />
                </div>
            }
        </div >
    )
}

export default HeaderBar