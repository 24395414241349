import React, { useEffect } from 'react'
import HeaderBar from '../components/HeaderBar'
import Footer from '../components/Footer'
import { useNavigate, useParams } from 'react-router-dom';
import { PaymentSuccessIcon } from '../components/icons';
import InteractiveBtn from '../components/InteractiveBtn';

const CheckoutCompleted = () => {
    const { transactionId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        console.log('transactionId:', transactionId)
    }, [transactionId])

    return <>
        <HeaderBar showProfile={true} showContact={true} />
        <div className='checkoutCompletedContainer'>
            <div className='paymentSuccessIcon'><PaymentSuccessIcon /></div>
            <div className='checkoutCompletedTitle'>Checkout completed</div>
            <div className='checkoutCompletedTxt'>
                Your content will be available for download once the payment has been processed.
            </div>
            <InteractiveBtn
                text='Go to profile'
                onClick={() => navigate('/profile')}
                className='loginRegisterBtn'
            />
        </div>
        <Footer />
    </>
}

export default CheckoutCompleted